<template>
  <div>
    <validation-observer ref="VFormInformConsent">
      <b-form @submit.prevent="doSubmit">
        <div class="row">
          <div class="col-md-12">
            <ul class="nav nav-tabs nav-tabs-bottom nav-justified mb-0">
              <li class="nav-item"><a href="javascript:;" @click="changeTab('IC')" 
              :class="activeTab == 'IC' ? 'nav-link active' : 'nav-link'">Inform
                  Consent</a>
              </li>
              <li v-if="isParent.rowEdit.arantl_is_transfusi == 'Y' && isParent.rowEdit.arantl_tr_transfusi_temp" class="nav-item"><a href="javascript:;" @click="changeTab('Transfusi')" 
              :class="activeTab == 'Transfusi' ? 'nav-link active' : 'nav-link'"> Permintaan
                  Tranfusi</a>
              </li>
              <li v-if="isParent.rowEdit.arantl_is_ph == 'Y' && isParent.rowEdit.arantl_ph_temp" class="nav-item"><a href="javascript:;" @click="changeTab('PH')" 
              :class="activeTab == 'PH' ? 'nav-link active' : 'nav-link'"> Permintaan
                  Pleubothomy</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-12">
            <div class="tab-content">
              <div :class="activeTab == 'IC' ?'tab-pane fade show active':'tab-pane fade'" id="inform-consent-lab">
                <div class="row">
                    <table class="table table-bordered table-striped table-hover table-sm patient-table">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Nama Tindakan</th>
                          <th>Tindakan Disetujui Oleh Pasien</th>
                          <th>Dokumen</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (informConcent||[])" :key="k+'informConsent'">
                          <td>{{k+1}}</td>
                          <td>{{v.aranic_name||"-"}}</td>
                          <td>
                            <span>{{v.aranic_is_pasien_setuju == 'Y'?'Setuju':'Tidak Setuju'}}</span>
                          </td>
                          <td>
                            <a class="btn_view" v-if="v.aranic_file" :href="isParent.uploader(v.aranic_file)" target="_blank"><i class="icon-file-download"></i></a>
                            <span v-else> - </span>
                          </td>
                        </tr>

                        <!---->
                      </tbody>
                    </table>
                </div>
              </div>
              <div :class="activeTab == 'Transfusi' ?'tab-pane fade show active':'tab-pane fade'"  id="form-permintaan-transifusi">
                <div class="row">
                  <div class="col-md-4 mt-2">
                    <div class="form-group">
                      <h6>Telah Terima</h6>
                      <table>
                        <tr>
                          <td>Gol </td>
                          <td>
                            <b-form-input class="ml-3 mb-2" v-model="isParent.rowEdit.arantl_tr_terima_gol" />
                          </td>
                        </tr>
                        <tr>
                          <td>Nama </td>
                          <td>
                            <b-form-input class="ml-3 mb-2" v-model="isParent.rowEdit.arantl_tr_terima_nama" />
                          </td>
                        </tr>
                        <tr>
                          <td>Alamat </td>
                          <td>
                            <b-form-textarea class="ml-3 mb-2" v-model="isParent.rowEdit.arantl_tr_terima_alamat" />
                          </td>
                        </tr>
                      </table>

                      <button type="button" @click="save()" class="btn btn-success mt-2">Simpan
                      </button>
                    </div>
                  </div>
                  <div class="col-md-8 mt-2">
                    <div class="document-framer">
                      <div class="df-content" v-html="isParent.rowEdit.arantl_tr_transfusi_temp">
                      </div>
                    </div>
                    <button type="button" @click="save(true,'data')" class="btn btn-primary mt-2">Unduh Dokumen
                    </button>
                    <button type="button" @click="save(true,'temp')" class="btn btn-success ml-2 mt-2">Unduh Template
                    </button>
                  </div>
                  
                </div>
              </div>

              <div :class="activeTab == 'PH' ?'tab-pane fade show active':'tab-pane fade'"  id="form-permintaan-transifusi">
                <div class="row">
                  <div class="col-md-8 offset-md-2 mt-2">
                    <div class="document-framer">
                      <div class="df-content" v-html="isParent.rowEdit.arantl_ph_temp">
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-md-8 offset-md-2 mt-2">
                    <button type="button" @click="unduhDokumenPhleubotomy()" class="btn btn-primary">Unduh Dokumen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-12">
            <div class="text-right">
            <button type="button" class="btn" @click="isParent.openModal = false" data-dismiss="modal">Batal</button>
            <!--
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
            </button>
            -->
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>

    
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="xl" ok-only>
      <table class="table table-bordered table-sm table-input">
      <thead>
          <tr>
          <td colspan="3">
              <div class="result_tab">
                  <h4>Nama Tindakan</h4>
                  <p>{{rowIC.aranic_name}}</p>
              </div>
          </td>
          </tr>
          <tr>
          <th width="24">No.</th>
          <th>Jenis Informasi</th>
          <th>Isi Informasi</th>
          </tr>
      </thead>
      <tbody>
          <tr>
          <td>1</td>
          <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
          <td>
          {{rowIC.aranic_diagnosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>2</td>
          <td>Dasar Diagnosis</td>
          <td>
          {{rowIC.aranic_dasar_diagnosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>3</td>
          <td>Tindakan Kedokteran</td>
          <td>
          {{rowIC.aranic_tindakan_dokter||"-"}}
          </td>
          </tr>
          <tr>
          <td>4</td>
          <td>Indikasi Tindakan</td>
          <td>
          {{rowIC.aranic_indikasi_tindakan||"-"}}
          </td>
          </tr>
          <tr>
          <td>5</td>
          <td>Tata Cara</td>
          <td>
          {{rowIC.aranic_tata_cara||"-"}}
          </td>
          </tr>
          <tr>
          <td>6</td>
          <td>Tujuan</td>
          <td>
          {{rowIC.aranic_tujuan||"-"}}
          </td>
          </tr>
          <tr>
          <td>7</td>
          <td>Risiko</td>
          <td>
          {{rowIC.aranic_risiko||"-"}}
          </td>
          </tr>
          <tr>
          <td>8</td>
          <td>Komplikasi</td>
          <td>
          {{rowIC.aranic_kompilasi||"-"}}
          </td>
          </tr>
          <tr>
          <td>9</td>
          <td>Prognosis</td>
          <td>
          {{rowIC.aranic_prognosis||"-"}}
          </td>
          </tr>
          <tr>
          <td>10</td>
          <td>Alternatif & Risiko</td>
          <td>
          {{rowIC.aranic_alternatif||"-"}}
          </td>
          </tr>
          <tr>
          <td>11</td>
          <td>Lain-lain</td>
          <td>
          {{rowIC.aranic_lainnya||"-"}}
          </td>
          </tr>
      </tbody>
      <tfoot>
          <tr>
          <td width="50%" colspan="2">
              <div class="result_tab">
              <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
              <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
              <p v-else>Tindakan ditolak</p>
              </div>
          </td>
          <td width="50%">
              <div class="result_tab">
              <h4>Persetujuan tindakan diberikan kepada</h4>
              <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
              <p v-else>Penanggung Jawab</p>
              </div>
          </td>
          </tr>
      </tfoot>
      </table>
    </b-modal>

      
    <validation-observer ref="VFormModal">
    <b-modal v-model="openTemplateICModal" @ok.prevent="unduhtemplateIC(rowIC)" ok-title="Unduh Template" title="Unduh Template Dokumen" size="lg">
        <div class="col-md-12 col-12 mb-2">
          <b-form-group class="mb-0">
            <label>Dokter Pelaksana</label>
            <v-select placeholder=" -- Pilih Dokter Pelaksana -- " v-model="rowIC.dokter_pelaksana"
              :options="isParent.mDokterDPJP" label="text" :reduce="v=>v.text"></v-select>
          </b-form-group>
        </div>
        <table class="table table-bordered table-sm text-uppercase">
            <tbody>
                <tr>
                    <td colspan="3" class="bg-primary">Data Pasien</td>
                </tr>
                <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Nama Pasien</h4>
                        <p>{{isParent.row.ap_fullname||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Usia</h4>
                        <p>{{isParent.row.ap_usia||"0"}} Tahun</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Alamat</h4>
                        <p>{{isParent.row.ap_address||"-"}}</p>
                        </div>
                    </td>
                </tr>
                <!-- == 'PJ'-->
                <template v-if="rowIC.aranic_persetujuan_tindakan">
                <tr>
                    <td colspan="3" class="bg-primary">Data Penanggung Jawab</td>
                </tr>
                <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Nama Penanggung Jawab</h4>
                        <b-form-input v-model="rowIC.aranic_nama_pj" type="text" name="NamaPenanggung" id="NamaPenanggung" class="form-control"
                        placeholder="Nama Penanggung Jawab"/>
                        <VValidate 
                            name="Nama Penanggung Jawab" 
                            v-model="rowIC.aranic_nama_pj" 
                            :rules="{required: 1}"
                        />
                        </div>
                    </td>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>Usia Penanggung Jawab</h4>
                        <b-form-input v-model="rowIC.aranic_usia_pj" type="text" name="UsiaPenanggung" id="UsiaPenanggung"
                          class="form-control" placeholder="Usia Penanggung Jawab" />
                        <VValidate name="Usia Penanggung Jawab" v-model="rowIC.aranic_usia_pj" :rules="{required: 1}" />
                      </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Alamat Penanggung Jawab</h4>
                        <b-form-input v-model="rowIC.aranic_address_pj" type="text" name="alamat" id="alamat" class="form-control"
                        placeholder="Alamat Penanggung Jawab"/>
                        <VValidate 
                            name="Alamat Penanggung Jawab" 
                            v-model="rowIC.aranic_address_pj" 
                            :rules="{required: 1}"
                        />
                        </div>
                    </td>
                </tr>
                </template>
            </tbody>
        </table>
    </b-modal>
    </validation-observer>

  </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  data(){
    return {
      activeTab: 'IC',
      
      openInformConsent: false,
      informConcent: [],
      rowIC: {},
      openModalICDetail: false,
      dataPJ: {},
      openTemplateICModal: false,
      openImplant: false,
      rowImplant: {}
    }
  },
  methods: {

    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.isParent.rowEdit[field] = null
      this.autoSave(this.isParent.rowEdit)
    },
    output(refs,field) {
      let { isEmpty, data } = this.$refs[refs].saveSignature()
      if(!isEmpty){
        this.isParent.rowEdit[field] = data
        this.autoSave(this.isParent.rowEdit)
      }
    },

    autoSave: _.debounce(function (data) {
      this.getData()
    },1000),

    getData(){
      this.isParent.loadingOverlay = true
      let data = this.isParent.rowEdit
      data.id = this.isParent.rowEdit.arantl_id
      data.aranr_id = this.isParent.rowEdit.arantl_aranr_id
      data.type = 'get-inform-consent'

      Gen.apiRest(
          "/do/"+this.isParent.modulePage,
          {data: data}, 
          "POST"
      ).then(res=>{
        let resp = res.data
        this.isParent.loadingOverlay = false
        this.informConcent = resp.informConcent
        this.isParent.rowEdit = resp.rowEdit

        $(".sign-container canvas").attr("width",400)
        $(".sign-container canvas").attr("height",160)                
        this.$nextTick(() => {
          if(this.$refs['ttdKeluarga']){
            this.$refs['ttdKeluarga'].fromDataURL(this.isParent.rowEdit.arantl_tr_keluarga_ttd)
          } 
          if(this.$refs['ttdPenerima']){
            this.$refs['ttdPenerima'].fromDataURL(this.isParent.rowEdit.arantl_tr_penerima_ttd)
          } 
          if(this.$refs['ttdPeminta']){
            this.$refs['ttdPeminta'].fromDataURL(this.isParent.rowEdit.arantl_ph_peminta_ttd)
          } 
        })
      })
    },
    changeTab(tab){
      this.activeTab = tab
    },
    // inform consent
    openInformConsentDetail(v){
        Gen.apiRest('/do/' + this.isParent.modulePage, {
            data: {
              type: 'get-data-ic-single',
              id: v.aranic_id,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.openModalICDetail = true                   
        })
    },
    openTemplateIC(v){
      this.rowIC = v
      this.rowIC.aranic_nama_pj = this.rowIC.aranic_nama_pj||this.isParent.row.arpj_fullname
      this.rowIC.aranic_address_pj = this.rowIC.aranic_address_pj||this.isParent.row.arpj_address
      this.rowIC.aranic_usia_pj = this.rowIC.aranic_usia_pj||this.isParent.row.ap_usia_with_ket
      this.openTemplateICModal = true
      this.$set(this.rowIC, 'dokter_pelaksana', this.rowIC.dokter_pelaksana||this.isParent.rowReg.bu_full_name)
    },
  
    uploadDok(v,k=''){
      Gen.apiRest('/do/' + this.isParent.modulePage, {
        data: {
            type: 'update-data-ic',
            id: v.aranic_id,
            aranic_file: v.aranic_file,
            aranic_nama_pj: v.aranic_nama_pj,
            aranic_address_pj: v.aranic_address_pj,
            aranic_usia_pj: v.aranic_usia_pj,
        }
      }, 'POST').then(res => {
        if(k){
            this.informConcent[k]['aranic_status'] = 'DONE' 
        }
      })
    },
    unduhtemplateIC(v){
      this.$refs['VFormModal'].validate().then(success => {
        if(success){
            this.uploadDok(v)                
            setTimeout(()=>{
                this.openTemplateICModal = false
                let data = {exptype: 'xlsx', type: "export", id: v.aranic_id, regID: this.isParent.rowEdit.arantl_aranr_id, dokter_pelaksana: v.dokter_pelaksana}
                let self = this
                self.isParent.loadingOverlay = true
                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${'InformConsentRanap'}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                        responseType: 'blob'
                    },
                    success: data => 
                    {
                        self.isParent.loadingOverlay = false
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `${'Inform-consent'}-${this.isParent.row.ap_fullname}-${moment().format("YYYY-MM-DD")}-${this.isParent.rowReg.aranr_reg_code}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.isParent.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            },2000)
            
        }
      })
    },
    uploadDokStatus(v,k=''){
      Gen.apiRest('/do/' + this.isParent.modulePage, {
        data: {
            type: 'update-data-ic',
            id: v.aranic_id,
            aranic_file: v.aranic_file,
            aranic_nama_pj: v.aranic_nama_pj,
            aranic_address_pj: v.aranic_address_pj,
            aranic_usia_pj: v.aranic_usia_pj,
            aranic_status: 'DONE'
        }
      }, 'POST').then(res => {
          this.informConcent[k]['aranic_status'] = 'DONE' 
      })
    },
    save(isUnduhDokTransfusi=false,typeForm="data"){
      let data = this.isParent.rowEdit
      data.type = "update-data"
      data.typeForm = typeForm

      Gen.apiRest('/do/' + this.isParent.modulePage, {
        data: data
      }, 'POST').then(res => {
        let resp = res.data
        this.isParent.rowEdit = resp.rowEdit
        if(isUnduhDokTransfusi){
          this.unduhDokumenTransfusi()
        }
      })
    },
    unduhDokumenTransfusi(){
      let data = {exptype: 'xlsx', type: "export", id: this.isParent.rowEdit.arantl_id}
      let self = this
      self.isParent.loadingOverlay = true          

      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${'downloadDokumenTransfusi'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.isParent.loadingOverlay = false          

              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `Dokumen-Transfusi-${moment().format("YYYY-MM-DD")}.pdf`
              link.click()
          },
          fail: data => {
              self.isParent.loadingOverlay = false          

              alert('Not downloaded')
          }
      })
    },
    unduhDokumenPhleubotomy(){
      let data = {exptype: 'xlsx', type: "export", id: this.isParent.rowEdit.arantl_id}
      let self = this

      self.isParent.loadingOverlay = true          

      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${'downloadDokumenPh'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.isParent.loadingOverlay = false          

              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `Dokumen-Pheleubotomy-${moment().format("YYYY-MM-DD")}.pdf`
              link.click()
          },
          fail: data => {
              self.isParent.loadingOverlay = false          

              alert('Not downloaded')
          }
      })
    },
    doSubmit(){
      this.$refs['VFormInformConsent'].validate().then(success => {
        if(success){
          let data = this.isParent.rowEdit
          data.type = 'submit-inform-consent'
          this.isParent.loadingOverlay = true
          Gen.apiRest(
              "/do/"+this.isParent.modulePage,
              {data: data}, 
              "POST"
          ).then(res=>{
            this.isParent.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.$swal({
              title: resp.message,
              icon: resp.status,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              this.isParent.openModal = false
              this.isParent.apiGet()
            })
          }).catch(err => {
              this.isParent.loadingOverlay = false
              if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
              }
              this.isParent.doSetAlertForm(err)
          })
        }
      })
    },
    // inform consent
  },
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted() {
    this.getData()
  }
  
}
</script>