<template>
  <div>
    <validation-observer ref="VFormPemberianDarah">
      <b-form @submit.prevent="doSubmit">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Tanggal dilakukan<small class="txt_mandatory">*</small></label>
              <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                </div>
                <datepicker input-class="form-control transparent"
                placeholder="Pilih Tanggal" class="my-datepicker"
                calendar-class="my-datepicker_calendar" v-model="isParent.rowEdit.arantl_ph_tanggal_lab">
                </datepicker>
              </div>
              <VValidate :name="'Tanggal Dilakukan'"
                v-model="isParent.rowEdit.arantl_ph_tanggal_lab"
                :rules="{required: 1}" />
            </div>
          </div>
          
          <div class="col-md-12">
            <div class="form-group">
              <label>Jam dilakukan<small class="txt_mandatory">*</small></label>
              <div class="input-group">
                <span class="input-group-prepend input-group-addon">
                  <span class="input-group-text"><i class="icon-alarm"></i></span>
                </span>
                <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="isParent.rowEdit.arantl_ph_waktu_lab">
                </vue-timepicker>
              </div>
              <VValidate :name="'Jam Dilakukan'"
                v-model="isParent.rowEdit.arantl_ph_waktu_lab"
                :rules="{required: 1}" />
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label>Jumlah Kantong<small class="txt_mandatory">*</small></label>
              <b-form-input v-model="isParent.rowEdit.arantl_ph_jml_kantong_lab" :formatter="isParent.number" type="text" name="name" class="form-control" />
              <VValidate :name="'Jumlah Kantong'"
                v-model="isParent.rowEdit.arantl_ph_jml_kantong_lab"
                :rules="{required: 1}" />
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label>Petugas<small class="txt_mandatory">*</small></label>
              <b-form-input v-model="isParent.rowEdit.arantl_ph_petugas_lab" type="text" name="name" class="form-control" />
              <VValidate :name="'Petugas'"
                v-model="isParent.rowEdit.arantl_ph_petugas_lab"
                :rules="{required: 1}" />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="text-right">
            <button type="button" class="btn" @click="isParent.openModal = false" data-dismiss="modal">Batal</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
            </button>
            </div>
          </div>
        </div>
        
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default{
  components: {
    Datepicker,VueTimepicker
  },  
  methods: {
    doSubmit(){
      this.$refs['VFormPemberianDarah'].validate().then(success => {
        if(success){
          let data = this.isParent.rowEdit
          data.type = 'submit-pemberian-darah'
          this.isParent.loadingOverlay = true
          Gen.apiRest(
              "/do/"+this.isParent.modulePage,
              {data: data}, 
              "POST"
          ).then(res=>{
            this.isParent.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.$swal({
              title: resp.message,
              icon: resp.status,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              this.isParent.openModal = false
              this.isParent.apiGet()
            })
          }).catch(err => {
              this.isParent.loadingOverlay = false
              if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
              }
              this.isParent.doSetAlertForm(err)
          })
        }
      })  
    },
  },
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted() {
    this.isParent.rowEdit.arantl_ph_tanggal_lab =  this.isParent.rowEdit.arantl_ph_tanggal_lab||moment().format('YYYY-MM-DD')
    this.isParent.rowEdit.arantl_ph_waktu_lab = this.isParent.rowEdit.arantl_ph_waktu_lab||moment().format('HH:mm')
    this.isParent.rowEdit.arantl_ph_jml_kantong_lab =  this.isParent.rowEdit.arantl_ph_jml_kantong_lab||this.isParent.rowEdit.arantl_ph_jml_kantong
    this.isParent.rowEdit.arantl_ph_petugas_lab = this.isParent.user.fullName
  },
}
</script>