<template>
    <div class="content-wrapper">
        <div v-if="isList" class="content pt-0">
            <bo-page-title />
            <div class="card">
                <div class="card-header bg-white">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="form-row align-items-center">
                                <div class="col-md-4">
                                    <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                                </div>
                                <div class="col-md-8">
                                  <div class="form-group mb-0">
                                    <div class="input-group">
                                        <date-range-picker
                                            ref="picker"
                                            :locale-data="datePickerConfig.locale"
                                            :autoApply="datePickerConfig.autoApply"
                                            v-model="dateRange"
                                            :opens="'right'"
                                            :ranges="datePickerConfig.ranges"
                                            @update="updateValues"
                                        >
                                            <template v-slot:input="picker">
                                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                            </template>
                                        </date-range-picker>
                                        <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="row gx-1">
                                <div class="col-md">
                                    <div class="row gx-1">
                                        <div class="col-md-auto">
                                            <div class="form-group mb-0">
                                                <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right ><i class="icon-spinner11"></i></button>
                                            </div>
                                        </div>     
                                        
                                        <div class="col-md-4">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp"  :options="mDokterDPJP" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>      
                                        
                                        <div class="col-md-3">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Kelas -- " @input="doFill" v-model="filter.kelas"  :options="mKelas" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>  

                                        <div class="col-md-3">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Ruangan -- " @input="doFill" v-model="filter.ruangan"  :options="mRuangan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>                                                             
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                <input class="form-control"
                                    v-model="filter.search"
                                    @input="doSearch"
                                    placeholder="Ketik Nama/No. RM Pasien"
                                />
                                <div class="form-control-feedback">
                                    <i class="icon-search4 text-indigo"></i>
                                </div>
                                    
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    id="resetBtn"
                                    @click="doResetData()"
                                >Reset</b-button>
                            </div>
                        </div>
                    </div>
                </div>     

                     
                
                <div class="table-responsive">
                    <table
                        class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                        <thead>
                            <tr>
                                <th>NO.</th>
                                <th>NO. KEDATANGAN</th>
                                <th>NAMA/NO KTP</th>                              
                                <th>NO RM</th>
                                <th>RUANGAN</th>
                                <th>DIAGNOSA</th>
                                <th>DOKTER DPJP</th>    
                                <th>JENIS PERMINTAAN</th>    
                                <th>TINDAKAN</th>
                                <th>TANGGAL TINDAKAN</th>
                                <th>STATUS</th>
                                <th>AKSI</th>
                            </tr>
                        </thead>
                        
                        <tbody v-if="(dataList||[]).length">
                            <template v-for="(v,k) in (dataList||[])">
                                <tr :key="k" :class="v.arantl_is_transfusi == 'Y' && v.arantl_tr_status != 'DONE' ? 'table-warning':''">
                                  <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                                  <td>
                                    <span class="font-weight-semibold">{{v.aranr_reg_code}}</span>
                                  </td>
                                  <td>
                                   <div class="d-flex flex-column align-items-start">
                                      <a href="javascript:;" @click="toDetail(v.aranr_id)" class="font-weight-semibold border-bottom">{{v.ap_fullname||"-"}}</a>
                                      <small>{{v.ap_code}}</small>
                                      <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                                  </td>
                                  <td>
                                  
                                    <div class="font-weight-semibold">{{v.mrank_name||"-"}}</div> - 
                                    <small><span>{{getConfigDynamic(mRuangan,v.aranres_bangsal)||"-"}} </span></small>
                                    <span class="text-secondary" v-if="v.mranbed_name"> - {{v.mranbed_name || "-"}}</span>

                                    
                                  </td>
                                  <td>{{v.arantl_diagnosa||"-"}}</td>
                                  <td>{{getConfigDynamic(mDokterDPJP,v.aranr_dokter_id)||"-"}} </td>
                                  <td>
                                    <ul style="padding-left:10px;">
                                      <li v-if="v.arantl_is_lab == 'Y'">Periksa Lab</li>
                                      <li v-if="v.arantl_is_transfusi == 'Y'">Transfusi</li>
                                      <li v-if="v.arantl_is_ph == 'Y'">Phlebotomy</li>
                                      <li v-if="v.arantl_is_hiv == 'Y'">HIV</li>
                                    </ul>
                                  </td>
                                  <td>{{v.arantl_tindakan||"-"}}</td>
                                  <td>
                                    <span v-if="v.arantl_date">{{v.arantl_date | moment("DD MMMM YYYY, HH:mm")}}</span>
                                    <span v-else> - </span>

                                    <small class="font-weight-semibold" v-if="v.input_by"> dibuat oleh {{v.input_by}}</small>
                                  </td>
                                  <td>
                                  
                                    <div v-if="v.arantl_have_nilai_kritis_lab == 'Y'" class="badge bg-danger mb-1">Kritis </div>
                                    <div v-if="v.arantl_is_have_hasil == 'Y' && v.arantl_is_menunggu_hasil != 'Y'" class="badge bg-success mb-1">Hasil Sudah Diisi </div>
                                    <div v-if="v.arantl_is_menunggu_hasil == 'Y'" class="badge bg-warning mb-1">Hasil Pemeriksaan Belum Keluar</div>
                                    <div v-if="v.arantl_is_reaksi_transfusi == 'Y'" class="badge bg-warning mb-1">Terjadi Reaksi Transfusi</div>
                                    <div v-if="v.arantl_hiv_is_menunggu_hasil == 'Y'" class="badge bg-warning mb-1">Hasil Pemeriksaan HIV Belum Keluar</div>
                                                
                                    <span v-if="v.arantl_tr_is_hentikan == 'Y' && v.arantl_tr_status == 'DONE'" class="badge bg-danger mb-1">Transfusi Dihentikan</span>
                                    
                                    <span v-if="v.arantl_tr_is_hentikan == 'Y' && v.arantl_tr_status != 'DONE'" class="badge bg-danger mb-1">Transfusi Dihentikan Sementara</span>


                                    
                                    
                                    <template v-if="v.arantl_is_draft == 'Y'">
                                      <span class="badge bg-warning mb-1">Draft </span>
                                    </template>
                                    <template v-else>
                                      <div v-if="v.arantl_is_lab == 'Y'" v-b-tooltip.hover title="Status Laboratorium">
                                        <span v-if="v.arantl_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                                        <span v-else-if="v.arantl_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                                        <span v-else class="badge bg-success mb-1">Selesai </span>
                                      </div>
                                      
                                      <div v-if="v.arantl_is_transfusi == 'Y'" v-b-tooltip.hover title="Status Transfusi">
                                        <span v-if="v.arantl_tr_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                                        <span v-else-if="v.arantl_tr_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                                        <span v-else class="badge bg-success mb-1">Selesai </span>

                                        <span class="badge bg-danger mb-1">{{v.jumlah_selesai||"0"}}/{{v.arantl_tr_jumlah_kantong||"0"}} Jumlah Kantong</span>

                                      </div>

                                      <div v-if="v.arantl_is_ph == 'Y'" v-b-tooltip.hover title="Status Phlebotomy">
                                        <span v-if="v.arantl_ph_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                                        <span v-else-if="v.arantl_ph_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                                        <span v-else class="badge bg-success mb-1">Selesai </span>
                                      </div>

                                      <div v-if="v.arantl_is_hiv == 'Y'" v-b-tooltip.hover title="Status HIV">
                                        <span v-if="v.arantl_hiv_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                                        <span v-else-if="v.arantl_hiv_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                                        <span v-else class="badge bg-success mb-1">Selesai </span>
                                        
                                        <span v-if="v.arantl_hiv_approved == 'N' && v.arantl_hiv_status != 'WAITING'" class="badge bg-danger mb-1">Pemeriksaan HIV ditolak</span>
                                        <span v-else-if="v.arantl_hiv_approved == 'Y' && v.arantl_hiv_status != 'WAITING'" class="badge bg-success mb-1">Pemeriksaan HIV disetujui </span>
                                      </div>

                                    </template>
                                  </td>
                                  <td>
                                    <a href="javascript:;" @click="toModal(v,'xl','Detail Tindakan Lab','DetailTindakanLab')" v-if="moduleRole('view')" class="btn rounded-round btn-sm btn-icon alpha-blue border-blue" v-b-tooltip.hover title="Lihat Detail"><i class="icon-file-eye"></i></a>

                                    <a href="javascript:;" @click="toForm(v.arantl_id)" v-if="moduleRole('isi_hasil') && v.arantl_is_lab == 'Y'" class="btn rounded-round btn-sm btn-icon alpha-success border-success" v-b-tooltip.hover :title="v.arantl_is_have_hasil == 'Y' ? 'Ubah Hasil' : 'Isi Hasil'"><i class="icon-file-text3 text-success"></i></a>

                                    <a href="javascript:;" v-if="v.arantl_is_transfusi == 'Y' && moduleRole('form_transfusi') && v.arantl_tr_status != 'DONE'" @click="toModal(v,'md','Form Transfusi','FormTransfusi')" class="btn rounded-round btn-sm btn-icon alpha-warning border-warning" v-b-tooltip.hover title="Isi Form Transfusi "><i class="icon-pencil4"></i></a>

                                    <a href="javascript:;" @click="toModal(v,'xl','Dokumen Tindakan Lab','FormInfomConsent')" v-if="moduleRole('inform_consent') && (v.arantl_is_transfusi == 'Y' || v.arantl_is_ph == 'Y')" class="btn btn-icon rounded-round btn-sm alpha-indigo border-indigo text-indigo-800" v-b-tooltip.hover title="Lihat Inform Consent">
                                      <i class="fas fa-notes-medical"></i>
                                    </a>

                                    <a href="javascript:;" @click="toModal(v,'sm','Pemberian Kantong Darah Phlebotomy','PemberianKantongDarah')"  v-if="moduleRole('pemberian_kantong_darah') && v.arantl_is_ph == 'Y' && v.arantl_ph_status != 'DONE' && v.arantl_ph_is_verif != 'Y'" class="btn rounded-round btn-sm btn-icon alpha-warning border-warning" v-b-tooltip.hover title="Pemberian Kantong Darah Phlebotomy"><i class="fa-solid fa-hand-holding-droplet"></i></a>

                                    <a href="javascript:;" @click="toModal(v,'lg','Verifikasi Pengembalian Kantong Darah', 'VerifikasiPengembalian')" v-if="moduleRole('verifikasi_pengembalian_kantong') && v.arantl_is_ph == 'Y' && v.arantl_ph_is_return_from_lab  == 'Y' && v.arantl_ph_status != 'DONE'" class="btn rounded-round btn-sm btn-icon alpha-success border-success" v-b-tooltip.hover title="Verifikasi Pengembalian Kantong Darah"><i class="fa-solid fa-square-check text-success-800"></i></a>

                                    <a href="javascript:;" @click="toModal(v,'lg','Pemberian dan Pengembalian','RiwayatPengembalian')" v-if="moduleRole('riwayat_pemberian_pengembalian') && v.arantl_is_ph == 'Y' && v.arantl_is_done_ph  == 'Y'" class="btn rounded-round btn-sm btn-icon alpha-info border-info" v-b-tooltip.hover title="Riwayat Pemberian dan Pengembalian Darah"><i class="fa-solid fa-receipt text-info-800"></i></a>

                                    
                                    <a href="javascript:;" @click="toModal(v,'xl','Isi Hasil HIV','IsiHasilHIV')" v-if="moduleRole('isi_hasil_hiv') && v.arantl_is_hiv == 'Y' && v.arantl_hiv_approved  == 'Y'" class="btn rounded-round btn-sm btn-icon alpha-info border-info" v-b-tooltip.hover title="Isi hasil Pemeriksaan HIV"><i class="fa-solid fa-receipt text-info-800"></i></a>
                                  </td>
                                </tr>
                            </template>
                        </tbody>
                        <tbody v-if="data.data.length == 0">
                            <tr>
                                <td colspan="99">
                                <div class="text-center">
                                    <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                                </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="!data.data">   
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                        </tbody>
                    </table>

                    
                    <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                        <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-left"></i>
                        </a>
                        <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-right"></i>
                        </a>
                    </div>
                </div>
                
                <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">     <b-pagination
                    class="mb-0"
                    v-model="pageNo"
                    :per-page="data.per_page"
                    :total-rows="data.total"
                />
                </b-card-footer>

            </div>

            <b-modal
              v-model="openModal"
              :size="modalConfig.size"
              no-close-on-backdrop
              hide-footer
              :title="modalConfig.title"
              :body-class="isModal == 'DetailTindakanLab' ? 'p-0' : ''"
            >
              <FormTransfusi v-if="isModal == 'FormTransfusi'" />
              <FormInfomConsent v-if="isModal == 'FormInfomConsent'" />     
              <PemberianKantongDarah v-if="isModal == 'PemberianKantongDarah'" />
              <VerifikasiPengembalian v-if="isModal == 'VerifikasiPengembalian'" />   
              <RiwayatPengembalian v-if="isModal == 'RiwayatPengembalian'" /> 
              <DetailTindakanLab v-if="isModal == 'DetailTindakanLab'" /> 
              <IsiHasilHIV v-if="isModal == 'IsiHasilHIV'" />   

            </b-modal>
        </div>
        <template v-else>
          <Form />
        </template>
    </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  const moment = require('moment')
  import FormInfomConsent from './FormInfomConsent.vue'
  import PemberianKantongDarah from './PemberianKantongDarah.vue'
  import VerifikasiPengembalian from './VerifikasiPengembalian.vue'
  import RiwayatPengembalian from './RiwayatPengembalian.vue'
  import FormTransfusi from './FormTransfusi.vue'
  import DetailTindakanLab from './DetailTindakanLab.vue'
  import IsiHasilHIV from './IsiHasilHIV.vue'

  import Form from './Form.vue'

  const _ = global._
  import $ from 'jquery'
  import Gen from '@/libs/Gen.js'
  export default {
    extends: GlobalVue,
    components:{
      DateRangePicker,FormTransfusi,FormInfomConsent,PemberianKantongDarah,VerifikasiPengembalian,RiwayatPengembalian,Form,DetailTindakanLab,IsiHasilHIV
    },
    data(){
      return {
        idKey:'aranres_id',
        datePickerConfig: {
          startDate: new Date(moment().subtract(6, 'days')),
          endDate: new Date(),
          autoApply: true,
          ranges: {
              'Hari Ini': [new Date(), new Date()],
              '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
              '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
          },
          applyClass: 'btn-sm btn-primary',
          cancelClass: 'btn-sm btn-light',
          locale: {
              applyLabel: 'Terapkan',
              cancelLabel: 'Batal',
              direction: 'ltr',
              format: 'mm/dd/yyyy',
              separator: ' - ',
          }
        },
        dateRange: {
          startDate: new Date(moment().subtract(6, 'days')),
          endDate: new Date(),
        },
        mDokterDPJP: [],
        mKelas: [],
        mRuangan: [],
        mRanapBed: [],
        mKamar: [],

        modalConfig: {
          title: '',
          size: 'sm'
        },
        isModal: '',
        rowEdit: {},
        openModal: false
      }
    },
    // computed:{
    //   isDetail(){
    //     return this.$route.query.isDetail
    //   },
    // },
    methods: {
      toForm(id){
        this.$router.push({ name: this.modulePage, params: {pageSlug: id}}).catch(()=>{})
      },
      toDetail(regID){
        this.$router.push({ name: 'RanapPasienTindakanLaboratorium', params: {pageSlug: regID}}).catch(()=>{})
      },
      setSlide(){
        const responsiveTable = document.querySelector('.table-responsive')
        if(responsiveTable){
          const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
          const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
          if(responsiveTableScroll){
            // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
            Array.from(responsiveTableScrollActions).forEach(action => {
              action.addEventListener('click', e => {
                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                  
                responsiveTable.scroll({
                    left: scrollAmount,
                    behavior: 'smooth'
                })
              })
            })
          }
        }
      },
      doResetData(){
        this.doReset()
        this.dateRange =  {
          startDate: new Date(moment().subtract(6, 'days')),
          endDate: new Date(),
        }
        this.doConvertDate()
      },
      doConvertDate(){
        this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
        this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      },
      doPageOne(){
        this.filter.page = 1
      },
      doSearch: _.debounce(function(){
        this.doPageOne()
        this.doFilter()
      },500),
      doFill(){
        this.doPageOne()
        this.doFilter()
      },
      updateValues(e){
        this.doConvertDate()
        this.doFill()
      },
      doRefreshData(){
        this.apiGet()
        window.scrollTo(0,0)
      },
      getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
      },
      toModal(row,size,title,isModal){
        this.rowEdit = row
        this.rowEdit.arantl_hiv_hasil = (this.rowEdit.arantl_hiv_hasil||[])
        this.modalConfig = {
          title: title,
          size: size
        }
        this.isModal = isModal
        this.openModal = true
      },
    },
    mounted(){
      this.filter.dpjp = 'ALL'
      this.filter.kelas = 'ALL'
      this.filter.ruangan = 'ALL'
    
      this.doConvertDate()      
      this.apiGet()
          
      setTimeout(()=>{
        this.setSlide()
      },1000)
    },
    filters: {
      date(val) {
        return val ? moment(val).format("D MMM YYYY") : ""
      }
    },
    watch:{
      $route(){
        this.apiGet()
        setTimeout(()=>{
          if(this.isList){
            this.setSlide()
          }
        },1000)
      }
    }
  }

</script>