<template>
    <div>
        <validation-observer ref="VFormTransfusi">
            <b-form @submit.prevent="doSubmit">
            <div class="row">
              <div class="col-md-12 mb-3">
                <table class="table table-bordered table-sm text-uppercase">
                  <tbody>
                    <tr>
                      <td width="33%">
                        <div class="result_tab">
                          <h4>No. Reg</h4>
                          <p>{{isParent.rowEdit.aranr_reg_code||"-"}}</p>
                        </div>
                      </td>
                      <td width="33%">
                        <div class="result_tab">
                          <h4>No. Rekam Medis</h4>
                          <p>{{isParent.rowEdit.ap_code||"-"}}</p>
                        </div>
                      </td>
                      <td width="33%">
                        <div class="result_tab">
                          <h4>Nama Pasien</h4>
                          <p>{{isParent.rowEdit.ap_fullname||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="33%">
                        <div class="result_tab">
                          <h4>Tempat, Tanggal Lahir </h4>
                          <p>{{isParent.rowEdit.ap_pob}}, {{isParent.rowEdit.ap_dob | moment('DD MMMM YYYY')}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Golongan Darah</h4>
                          <p>{{isParent.rowEdit.arantl_tr_golongan_darah || "-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Jenis Darah</h4>
                          <p>{{isParent.rowEdit.arantl_tr_jenis_darah || "-"}}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>  

            <div v-for="(v,k) in (dataTransfusi||[])" :key="k">
              <div class="card mb-0 mt-1">
                <div class="card-header bg_head_panel d-flex align-items-center justify-content-between" 
                @click="v.isOpen = !v.isOpen">
                  <b-form-checkbox :disabled="v.arantlt_status == 'DONE'?true:false"  v-model="v.checked" class="form-check-input-styled" :name="k+'rad'" />           
                  <h6 class="card-title font-weight-semibold">
                  Kantong Ke #{{k+1}}</h6>
                  <i class="icon-chevron-down"></i>
                </div>
                <div class="card-body p-3" v-show="v.isOpen">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>No Kantong</label>
                        <b-form-input v-model="v.arantlt_no_kantong" type="text" class="form-control" />
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">Tanggal dilakukan</label>
                        <div class="input-group">
                          <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                          </div>
                          <datepicker input-class="form-control transparent"
                          placeholder="Pilih Tanggal" class="my-datepicker"
                          calendar-class="my-datepicker_calendar" v-model="v.arantlt_tanggal_dilakukan">
                          </datepicker>
                        </div>
                      </div>
                    </div>
                  </div>  
                </div>
              </div>
              
              <VValidate :name="'No Kantong #'+(k+1)"
                  v-if="v.checked" class="d-block"
                  v-model="v.arantlt_no_kantong"
                  :rules="{required: 1}" />

              <VValidate :name="'Tanggal Dilakukan #'+(k+1)"
                  v-if="v.checked" class="d-block"
                v-model="v.arantlt_tanggal_dilakukan"
                :rules="{required: 1}" />
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="text-right">
                    <button type="button" class="btn" @click="isParent.openModal = false" data-dismiss="modal">Batal</button>
                    <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
                    </button>
                    </div>
                </div>
            </div>
            </b-form>
        </validation-observer>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'
const moment = require('moment')

export default{
  extends: GlobalVue, 
  components: {
    Datepicker
  },
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    },
    isFillTransfusi(){
      let data = 0
      for(let i = 0; i < (this.dataTransfusi||[]).length; i++){
        if(this.dataTransfusi[i]['checked']){
          data = data + 1
        }
      }
      return data
    },
    
  },
  methods: {
    doSubmit(){
      if(!this.isFillTransfusi){
        return this.$swal({
          icon: 'error',
          title: 'Minimal Pilih Satu Data Transfusi',
        })
      }

      this.$refs['VFormTransfusi'].validate().then(success => {
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.isParent.rowEdit
              data.dataTransfusi = this.dataTransfusi
              data.type = "submit-transfusi"
              
              this.isParent.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.isParent.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.isParent.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    this.isParent.openModal = false
                    this.isParent.apiGet()
                  })
              }).catch(err => {
                  this.isParent.loadingOverlay = false
                  if (err) {
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response?.data?.title
                      err.message = err.response?.data?.message
                      err.messageError = err.message
                  }
                  this.isParent.doSetAlertForm(err)
              })
            }
          })
        }
      })
    },
    getDataTransfusi(id){
      let data = {
        id: id,
        type: "data-transfusi"
      }
      this.isParent.loadingOverlay = true
      Gen.apiRest(
      "/do/" + this.isParent.modulePage, {
        data: data
      },
      "POST"
      ).then(res => {
        let resp = res.data
        this.dataTransfusi = resp.dataTransfusi
        this.isParent.loadingOverlay = false
      })  
    }
  },
  data() {
    return {
      dataTransfusi: []
    }
  },
  mounted(){
    this.getDataTransfusi(this.isParent.rowEdit.arantl_id)
  }
}
</script>

