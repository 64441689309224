<template>
  <div>
    <validation-observer ref="VFormVerifikasiPengembalian">
      <b-form @submit.prevent="doSubmit">
        <div class="row">
          <div class="col-md-12">
            <table class="table table-bordered table-sm patient-table">
              <thead>
                <tr>
                  <th></th>
                  <th style="width:45%">Rawat Inap</th>
                  <th style="width:50%">Laboratorium</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tanggal/Jam</td>
                  <td>{{isParent.rowEdit.arantl_ph_pengembalian_tanggal | moment('DD MMM YYYY')}}, {{isParent.rowEdit.arantl_ph_pengembalian_waktu}} </td>
                  <td>
                    <div class="input-group col-md-12">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-calendar22"></i></span>
                      </div>
                      <datepicker :clear-button="true" input-class="form-control transparent h-38" 
                          calendar-class="my-datepicker"
                          v-model="isParent.rowEdit.arantl_ph_pengembalian_tanggal_lab">
                      </datepicker>
                      <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="isParent.rowEdit.arantl_ph_pengembalian_waktu_lab">
                      </vue-timepicker>
                              
                      <VValidate :name="'Tanggal Dilakukan'"
                        v-model="isParent.rowEdit.arantl_ph_pengembalian_tanggal_lab"
                        :rules="{required: 1}" />

                      <VValidate :name="'Jam Dilakukan'"
                        v-model="isParent.rowEdit.arantl_ph_pengembalian_waktu_lab"
                        :rules="{required: 1}" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Jumlah Kantong</td>
                  <td>{{isParent.rowEdit.arantl_ph_pengembalian_jml_kantong}}</td>
                  <td>
                    <b-form-input @input="changeKantong($event)" v-model="isParent.rowEdit.arantl_ph_pengembalian_jml_kantong_lab" :formatter="isParent.number" type="text" name="name" class="form-control" />
                    
                    <VValidate :name="'Jumlah Kantong'"
                      v-model="isParent.rowEdit.arantl_ph_pengembalian_jml_kantong_lab"
                      :rules="{required: 1}" />
                  </td>
                </tr>
                <tr>
                  <td>Jumlah Darah</td>
                  <td>
                    <table class="table table-bordered table-sm patient-table">
                      <tbody>
                        <tr v-for="(v,k) in (isParent.rowEdit.arantl_ph_kantong||[])" :key="k">
                          <th class="font-weight-bold" style="width: 40%;">Kantong {{k+1}}</th>
                          <td>
                            {{v||"-"}} cc
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table class="table table-bordered table-sm patient-table">
                      <tbody>
                        <tr v-for="(v,k) in (isParent.rowEdit.arantl_ph_kantong_lab||[])" :key="k">
                          <th class="font-weight-bold" style="width:30%;">Kantong {{k+1}}</th>
                          <td>
                            <div class="input-group">
                              <b-form-input v-model="isParent.rowEdit.arantl_ph_kantong_lab[k]" :formatter="isParent.number" class="form-control" />
                              <div class="input-group-prepend"><span class="input-group-text">CC</span>
                              </div>
                            </div>
                            <VValidate :name="'Kantong '+(k+1)"
                            v-model="isParent.rowEdit.arantl_ph_kantong_lab[k]"
                            :rules="{required: 1}" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>Petugas</td>
                  <td>{{isParent.rowEdit.arantl_ph_pengembalian_petugas||"-"}}</td>
                  <td>
                    <b-form-input v-model="isParent.rowEdit.arantl_ph_pengembalian_petugas_lab" type="text" name="name" class="form-control" />
                    
                    <VValidate :name="'Petugas'"
                      v-model="isParent.rowEdit.arantl_ph_pengembalian_petugas_lab"
                      :rules="{required: 1}" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="text-right">
            <button type="button" class="btn" @click="isParent.openModal = false" data-dismiss="modal">Batal</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
            </button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default{
  components: {
    Datepicker,VueTimepicker
  },  
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted() {
    
    this.isParent.rowEdit.arantl_ph_pengembalian_tanggal_lab =  this.isParent.rowEdit.arantl_ph_pengembalian_tanggal_lab||moment().format('YYYY-MM-DD')
    this.isParent.rowEdit.arantl_ph_pengembalian_waktu_lab = this.isParent.rowEdit.arantl_ph_pengembalian_waktu_lab||moment().format('HH:mm')
    this.isParent.rowEdit.arantl_ph_pengembalian_jml_kantong_lab = this.isParent.rowEdit.arantl_ph_pengembalian_jml_kantong
    this.isParent.rowEdit.arantl_ph_pengembalian_petugas_lab = this.isParent.user.fullName
    this.isParent.rowEdit.arantl_ph_kantong_lab = this.isParent.rowEdit.arantl_ph_kantong_lab || Array(this.isParent.rowEdit.arantl_ph_pengembalian_jml_kantong_lab)


  },
  methods: {
    changeKantong(e){
      let len = +e
      this.isParent.rowEdit.arantl_ph_kantong_lab = Array(len)
    },
    doSubmit(){
      this.$refs['VFormVerifikasiPengembalian'].validate().then(success => {
        if(success){
          let data = this.isParent.rowEdit
          data.type = 'submit-pengembalian-darah'
          this.isParent.loadingOverlay = true
          Gen.apiRest(
              "/do/"+this.isParent.modulePage,
              {data: data}, 
              "POST"
          ).then(res=>{
            this.isParent.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.$swal({
              title: resp.message,
              icon: resp.status,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              this.isParent.openModal = false
              this.isParent.apiGet()
            })
          }).catch(err => {
              this.isParent.loadingOverlay = false
              if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
              }
              this.isParent.doSetAlertForm(err)
          })
        }
      })  
    },
  },
}
</script>