<template>
  <div>
    <b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
      <b-tab title="Pemeriksaan Laboratorium">
        <div class="card shadow-0">
          <div class="card-body p-3">
            <table class="table table-bordered">
              <thead>
                <tr class="table-light">
                  <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Prioritas Pemeriksaan</h4>
                      <p v-if="rowData.arantl_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                      <p v-else>Non CITO</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Apakah pasien harus berpuasa?</h4>
                      <p>{{rowData.arantl_fasting == 'Y' ? 'Ya' : 'Tidak'}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div class="result_tab">
                      <h4>Catatan Permintaan</h4>
                      <p>{{rowData.arantl_catatan_permintaan||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body" v-if="rowData.arantl_is_lab == 'Y'">
            <h6 class="card-title font-weight-semibold">Pemeriksaan Laboratorium</h6>
            <div class="req-list-resume">
              <div class="row">
                <div class="col-lg-6">
                  <div class="row">
                    <template v-for="(v,k) in rowData.arantl_data">
                      <div class="col-md-6 col-lg-6" :key="k+'labData'" v-if="isShowHeadLabV2(k)">
                        <div class="card shadow-0 border">
                          <div class="card-header py-2 bg-light">
                            <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                          </div>
                          <div class="card-body py-2">
                            <template v-for="v1,k1 in (v.dubData||[])">
                              <div class="req-list" v-if="isShowHeadSubHeadLabV2(k,k1)" :key="k1+'labdatas'">
                                <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                                <template v-for="v2,k2 in (v1.data||[])">
                                  <div v-if="isShowLabV2(k,k1,k2)" :key="k2+'labsubdatas'">
                                    <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                    <span class="align-middle font-weight-semibold">{{v2.text||"-"}}</span>
                                    <span v-if="v2.notes">, {{v2.notes}}</span>
                                  </div>
                                </template>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </template>

                    <div class="col-md-6 col-lg-6" v-if="rowData.arantl_hasil_lainnya">
                      <div class="card shadow-0 border">
                        <div class="card-header py-2 bg-light">
                          <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                        </div>
                        <div class="card-body py-2">
                          <div class="req-list">
                            <div>
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span
                                class="align-middle font-weight-semibold">{{rowData.arantl_hasil_lainnya||"-"}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span v-if="!selectedLabInputV2()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="card">
                    <div class="row">
                      <div class="col-md-12">
                        <table class="table table-bordered table-striped table-sm patient-table">
                          <thead>
                            <tr>
                              <th>Hasil Pemeriksaan </th>
                              <th>Nilai Kritis </th>
                              <th> Hasil</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v,k) in (rowData.arantl_hasil||[])" :key="k+'hasil'">
                              <td>{{v.value}}<br />
                              </td>
                              <td>
                                {{v.nilai_kritis||"-"}}<br />
                                {{v.jam_keluar}}
                              </td>
                              <td>
                                <div class="result_tab form-group mb-0">
                                  <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)"
                                    target="_blank"><i class="icon-file-download"></i></a>
                                </div>
                              </td>
                            </tr>
                            <tr v-if="!(rowData.arantl_hasil||[]).length">
                              <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body" v-if="rowData.arantl_is_transfusi == 'Y'">
            <h6 class="card-title font-weight-semibold">Tindakan Transfusi</h6>
            <table class="table table-bordered table-sm">
              <tbody>
                <tr>
                  <td colspan="3" width="50%">
                    <div class="result_tab">
                      <h4>Pasien Pernah Transfusi?</h4>
                      <p>{{getConfigDynamic(Config.mr.pernahTidakPernah,rowData['arantl_tr_pasien_transfusi'])||"-"}}
                      </p>
                    </div>
                  </td>
                  <td colspan="3" width="50%">
                    <div class="result_tab">
                      <h4>Indikasi Transfusi</h4>
                      <p>{{rowData.arantl_tr_indikasi_transfusi||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" width="33%">
                    <div class="result_tab">
                      <h4>Golongan Darah</h4>
                      <p>{{rowData.arantl_tr_golongan_darah||"-"}}</p>
                    </div>
                  </td>
                  <td colspan="2" width="33%">
                    <div class="result_tab">
                      <h4>Jumlah Kantong</h4>
                      <p>{{rowData.arantl_tr_jumlah_kantong||"-"}}</p>
                    </div>
                  </td>
                  <td colspan="2" width="33%">
                    <div class="result_tab">
                      <h4>Jenis Darah yang diperlukan</h4>
                      <p>{{rowData.arantl_tr_jenis_darah||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div class="result_tab">
                      <h4>Tanggal diperlukan</h4>
                      <p>{{rowData.arantl_tr_tanggal | moment("DD MMMM YYYY")}}</p>
                    </div>
                  </td>
                  <td colspan="2">
                    <div class="result_tab">
                      <h4>Informed Consent</h4>
                      <p>
                        <a href="javascript:;"
                          @click="openInformConsentDetailSingle(rowData,'arantl_tr_inform_consent_id','Tindakan Transfusi')"
                          v-if="rowData.arantl_tr_inform_consent_id"
                          class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mr-1">
                          <i class="icon-file-eye2"></i>
                        </a>
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body" v-if="rowData.arantl_is_ph == 'Y'">
            <h6 class="card-title font-weight-semibold">Tindakan Phlebotomy</h6>
            <table class="table table-sm table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Tanggal diperlukan</h4>
                      <p>{{rowData.arantl_ph_tanggal|moment('DD MMMM YYYY')}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Jumlah Kantong</h4>
                      <p>{{rowData.arantl_ph_jml_kantong||0}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Informed Consent</h4>
                      <p>
                        <a href="javascript:;"
                          @click="openInformConsentDetailSingle(rowData,'arantl_ph_inform_consent_id','Tindakan Phlebotomy')"
                          v-if="rowData.arantl_ph_inform_consent_id"
                          class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal mr-1">
                          <i class="icon-file-eye2"></i>
                        </a>
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body" v-if="rowData.arantl_is_hiv == 'Y'">
            <h6 class="card-title font-weight-semibold">Pemeriksaan HIV</h6>
            <table class="table table-sm table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Tanggal diperlukan</h4>
                      <p>{{rowData.arantl_hiv_date|moment('DD MMMM YYYY')}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Apakah Pasien Setuju Dengan Tindakan HIV</h4>
                      <p>{{getConfigDynamic(Config.mr.yesNoOptV2,rowData.arantl_hiv_approved)||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>File Pemeriksaan Hiv</h4>
                      <a class="btn_view" v-if="rowData.arantl_hiv_file"
                        :href="$parent.$parent.$parent.uploader(rowData.arantl_hiv_file)" target="_blank"><i
                          class="icon-file-download"></i></a>
                      <span v-else> - </span>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Dokumen Kajian HIV</h4>
                      <a class="btn_view" v-if="rowData.arantl_hiv_kajian_doc"
                        :href="$parent.$parent.$parent.uploader(rowData.arantl_hiv_kajian_doc)" target="_blank"><i
                          class="icon-file-download"></i></a>
                      <span v-else> - </span>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td colspan="2">
                    <div class="result_tab">
                      <table class="table table-bordered table-striped table-sm patient-table">
                        <thead>
                          <tr>
                            <th>Hasil Pemeriksaan </th>
                            <th>Nilai Kritis </th>
                            <th> Hasil</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(v,k) in (rowData.arantl_hiv_hasil||[])" :key="k+'hasil'">
                            <td>{{v.value}}<br />
                            </td>
                            <td>
                              {{v.nilai_kritis||"-"}}<br />
                              {{v.jam_keluar}}
                            </td>
                            <td>
                              <div class="result_tab form-group mb-0">
                                <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i
                                    class="icon-file-download"></i></a>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="!(rowData.arantl_hiv_hasil||[]).length">
                            <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>

                </tr>
              </tbody>

            </table>
          </div>

        </div>
      </b-tab>
      <b-tab title="Monitoring Transfusi" v-if="(dataTransfusi||[]).length">
        <div>
          <div class="bg_card_blue p-3">
            <table class="table table-borderless table-sm text-uppercase">
              <tbody>
                <tr>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>No. Reg</h4>
                      <p>{{isParent.rowReg.aranr_reg_code||"-"}}</p>
                    </div>
                  </td>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>No. Rekam Medis</h4>
                      <p>{{isParent.row.ap_code||"-"}}</p>
                    </div>
                  </td>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>Nama Pasien</h4>
                      <p>{{isParent.row.ap_fullname||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="33%">
                    <div class="result_tab">
                      <h4>Tempat, Tanggal Lahir </h4>
                      <p>{{isParent.row.ap_pob}}, {{isParent.row.ap_dob | moment('DD MMMM YYYY')}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Golongan Darah</h4>
                      <p>{{rowData.arantl_tr_golongan_darah||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Jenis Darah</h4>
                      <p>{{rowData.arantl_tr_jenis_darah||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-12">
          <ul class="nav nav-tabs nav-tabs-bottom nav-justified mt-2 mb-0">
            <li class="nav-item" v-for="(v,k) in (dataTransfusi||[])" :key="k">
              <a href="javascript:;" @click="changeTab(k)" :class="activeTab == (k) ? 'nav-link active' : 'nav-link'"
                data-toggle="tab">Kantong ke #{{k+1}} - ({{v.arantlt_no_kantong}})</a>
            </li>
          </ul>
        </div>
        <div class="mt-2">
          <div class="card mb-0">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Serah Terima Darah</h6>
            </div>
            <div class="card-body p-3">
              <div>
                <table class="table table-bordered table-sm">
                  <tbody>
                    <tr>
                      <td width="33%" style="align-top">
                        <div class="result_tab">
                          <h4>Petugas Bank Darah</h4>
                          <p>{{rowTransfusi.arantlt_petugas||"-"}}</p>
                          <img v-if="rowTransfusi.arantlt_petugas_ttd" :src="rowTransfusi.arantlt_petugas_ttd" alt="" />
                        </div>
                      </td>
                      <td width="33%" style="align-top">
                        <div class="result_tab">
                          <h4>Penerima Darah</h4>
                          <p>{{rowTransfusi.arantlt_penerima||"-"}}</p>
                          <img v-if="rowTransfusi.arantlt_penerima_ttd" :src="rowTransfusi.arantlt_penerima_ttd"
                            alt="" />
                        </div>
                      </td>
                      <td width="33%" style="align-top">
                        <div class="result_tab">
                          <h4>Waktu Serah Terima</h4>
                          <p>{{rowTransfusi.arantlt_date | moment('DD MMM YYYY')}} {{rowTransfusi.arantlt_jam}}</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="33%" style="align-top">
                        <div class="result_tab">
                          <h4>Hentikan Transfusi</h4>
                          <p>{{rowTransfusi.arantlt_is_hentikan == 'Y' ? "Ya" : "Tidak"}}</p>
                        </div>
                      </td>
                      <td width="33%" style="align-top">
                        <div class="result_tab">
                          <h4>File Transfusi</h4>
                          <a class="btn_view" v-if="rowTransfusi.arantlt_file"
                            :href="$parent.$parent.$parent.uploader(rowTransfusi.arantlt_file)" target="_blank"><i
                              class="icon-file-download"></i></a>
                          <span v-else> - </span>
                        </div>
                      </td>
                      <td width="33%" style="align-top">
                        <div class="result_tab">
                          <h4>Keterangan Penghentian Transfusi</h4>
                          <p>{{rowTransfusi.arantlt_keterangan||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <table class="table table-bordered table-sm patient-table">
            <div class="display-c" v-for="(v,k) in (dataMonitoring||[])" :key="k">
              <thead>
                <tr>
                  <th colspan="2">Monitoring Kantong ke-{{(k+1)}}</th>
                </tr>
              </thead>
              <tbody v-for="(v1,k1) in (v.data||[])" :key="k1+'ch'">
                <tr v-for="(v2,k2) in (v1.data||[])" :key="k2+'tf'">
                  <td :class="v1.color">{{v1.text}}</td>
                  <td>
                    <div class="result_tab mb-3">
                      <h4>Pukul</h4>
                      <p>{{v2.arantm_date | moment('DD MMM YYYY')}} {{v2.arantm_waktu}}</p>
                    </div>
                    <div class="card mb-0">
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                      </div>
                      <div class="card-body p-3">
                        <table class="table table-bordered table-sm">
                          <tbody>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Tekanan Darah</h4>
                                  <p v-if="v2.arantm_ttv_tekanan_darah_min && v2.arantm_ttv_tekanan_darah_max">
                                    {{v2.arantm_ttv_tekanan_darah_min||"-"}}
                                    /{{v2.arantm_ttv_tekanan_darah_max||"-"}}mmHg</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Nadi</h4>
                                  <p v-if="v2.arantm_ttv_nadi">{{v2.arantm_ttv_nadi||"-"}}x/mnt</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Suhu</h4>
                                  <p v-if="v2.arantm_ttv_suhu">{{v2.arantm_ttv_suhu||"-"}}°C</p>
                                  <p v-else></p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Pernafasan</h4>
                                  <p v-if="v2.arantm_ttv_pernafasan">{{v2.arantm_ttv_pernafasan||"-"}}x/mnt</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Volume Urine</h4>
                                  <p v-if="v2.arantm_ttv_volume_urine">{{v2.arantm_ttv_volume_urine||"-"}}L</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Warna Urine</h4>
                                  <p v-if="v2.arantm_ttv_warna_urine">{{v2.arantm_ttv_warna_urine||"-"}}</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="card mt-2">
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Gejala &amp; Tanda Reaksi</h6>
                      </div>
                      <div class="card-body p-3">
                        <div class="row g-2">
                          <div class="col-md-auto" v-for="(v3,k3) in (v2.arantm_gejala||[])" :key="k3">
                            <div class="det-check-item"><i
                                class="icon-checkmark-circle text-success"></i><span>{{v3}}</span></div>
                          </div>
                          <span v-if="!(v2.arantm_gejala||[]).length">Tidak ada Reaksi</span>
                        </div>
                      </div>
                    </div>

                    <table class="table  table-sm  table-bordered" v-if="(v2.arantm_gejala||[]).length">
                      <thead>
                        <tr>
                          <th>Reaksi Transfusi {{v2.arantm_reaksi_type||"-"}}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <th>
                            <ul v-if="(v2.arantm_reaksi_transfusi||[]).length">
                              <li v-for="(v4,k4) in (v2.arantm_reaksi_transfusi||[])" :key="k4">
                                {{v4}}</li>
                            </ul>
                            <span v-else>Tidak ada data</span>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </div>
          </table>
        </div>
      </b-tab>
    </b-tabs>


    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
      <table class="table table-bordered table-sm table-input">
        <thead>
          <tr>
            <td colspan="3">
              <div class="result_tab">
                <h4>Nama Tindakan / ICD-9-CM</h4>
                <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code"
                    class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
              </div>
            </td>
          </tr>
          <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
              {{rowIC.aranic_diagnosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
              {{rowIC.aranic_dasar_diagnosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
              {{rowIC.aranic_tindakan_dokter||"-"}}
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
              {{rowIC.aranic_indikasi_tindakan||"-"}}
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
              {{rowIC.aranic_tata_cara||"-"}}
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
              {{rowIC.aranic_tujuan||"-"}}
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
              {{rowIC.aranic_risiko||"-"}}
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
              {{rowIC.aranic_kompilasi||"-"}}
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
              {{rowIC.aranic_prognosis||"-"}}
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
              {{rowIC.aranic_alternatif||"-"}}
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
              {{rowIC.aranic_lainnya||"-"}}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td width="50%" colspan="2">
              <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
              </div>
            </td>
            <td width="50%">
              <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </b-modal>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  extends: GlobalVue,
  data() {
    return {
      rowData: {},
      rowIC: {},
      openModalICDetail: false,
      
      dataMonitoring: [],
      dataTransfusi: [],
      rowTransfusi: {},
      activeTab: 0,
    }
  },
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    }
  },
  mounted() {
    this.apiGetForm()
    this.getDataMonitoring()
  },
  methods: {
    apiGetForm(params = {}, page = 1){
      this.loadingOverlay = true
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = 'RanapPasienTindakanLaboratorium'+'/'+this.$route.params.pageSlug+'/'+this.isParent.rowEdit.arantl_id      
      Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
          console.log(this.rowData)
      }).catch(()=>{
          this.loadingOverlay = false
      })

      Gen.apiRest(
        "/get/"+url+'?master=1', 
        {
        params: Object.assign({page: page}, paramsQuery, params.query||{})
        }
      ).then(res=>{
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
      })
    },
    isShowHeadLabV2(i){
      let isData = 0
      for(let j = 0; j < (this.rowData.arantl_data[i]['dubData']||[]).length; j++){
        for(let k = 0; k < (this.rowData.arantl_data[i]['dubData'][j]['data']||[]).length; k++){  
          if(this.rowData.arantl_data[i]['dubData'][j]['data'][k]['selected']){
            isData += 1
          }
        }
      }
      return isData
    },
    isShowHeadSubHeadLabV2(i,j){
      let isData = 0
      for(let k = 0; k < (this.rowData.arantl_data[i]['dubData'][j]['data']||[]).length; k++){  
        if(this.rowData.arantl_data[i]['dubData'][j]['data'][k]['selected']){
          isData += 1
        }
      }
      return isData
    },
    isShowLabV2(i,j,k){
      let isData = 0
      if(this.rowData.arantl_data[i]['dubData'][j]['data'][k]['selected']){
        isData += 1
      }
      return isData
    },
    selectedLabInputV2(){
      let data = []
      for(let ik = 0; ik < (this.rowData.arantl_data||[]).length; ik++){
        for(let jk = 0; jk < (this.rowData.arantl_data[ik]['dubData']||[]).length; jk++){
          for(let kk = 0; kk < (this.rowData.arantl_data[ik]['dubData'][jk]['data']||[]).length; kk++){  
              if(this.rowData.arantl_data[ik]['dubData'][jk]['data'][kk]['selected']){
                data.push(this.rowData.arantl_data[ik]['dubData'][jk]['data'][kk]['text'])
              }
          }
        }
      }
      if(this.rowData.arantl_hasil_lainnya){
        data.push(this.rowData.arantl_hasil_lainnya)
      }
      return data.join(", ")
    },
    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    openInformConsentDetailSingle(row,field,tindakan){
      Gen.apiRest('/do/' + 'RanapPasienTindakanLaboratorium', {
        data: {
            type: 'get-data-ic-single',
            id: row[field],
        }
      }, 'POST').then(res => {
        this.rowIC = res.data.row 
        this.rowIC.tindakan = tindakan
        this.rowIC.id_tindakan = row[field]
        this.rowIC.field = field
      
        this.openModalICDetail = true                   
      })
    },

    getDataMonitoring(){
      this.loadingOverlay = true
      let data = {
        id : this.isParent.rowEdit.arantl_id      
      }
      data.type = 'get-monitoring'
      Gen.apiRest(
          "/do/"+'RanapPasienTindakanLaboratorium',
          {data: data}, 
          "POST"
      ).then(res=>{
        let resp = res.data
        this.loadingOverlay = false

        this.dataTransfusi = resp.dataTransfusi
        if((this.dataTransfusi||[]).length){
          this.dataMonitoring = this.dataTransfusi[0]['dataMonitoring']
          this.rowTransfusi = this.dataTransfusi[0]

          $(".sign-container canvas").attr("width",400)
          $(".sign-container canvas").attr("height",160)                
          this.$nextTick(() => {
            if(this.$refs['ttdPenerima']){
              this.$refs['ttdPenerima'].fromDataURL(this.rowTransfusi.arantlt_penerima_ttd)
            } 
            if(this.$refs['ttdPetugas']){
              this.$refs['ttdPetugas'].fromDataURL(this.rowTransfusi.arantlt_petugas_ttd)
            } 
          })
        }
      })
    },
    
    
    changeTab(tab){
      this.activeTab = tab
      this.dataMonitoring = this.dataTransfusi[tab]['dataMonitoring']  
      this.rowTransfusi = this.dataTransfusi[tab]
      
      $(".sign-container canvas").attr("width",400)
      $(".sign-container canvas").attr("height",160)                
      this.$nextTick(() => {
        if(this.$refs['ttdPenerima']){
          this.$refs['ttdPenerima'].fromDataURL(this.rowTransfusi.arantlt_penerima_ttd)
        } 
        if(this.$refs['ttdPetugas']){
          this.$refs['ttdPetugas'].fromDataURL(this.rowTransfusi.arantlt_petugas_ttd)
        } 
      })
    },
  }
}

</script>

