<template>
  <div>
    <validation-observer ref="VFormHIV">
      <b-form @submit.prevent="doSubmit">
      <div class="card">
        <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">UPLOAD HASIL LABORATORIUM</h6>
        </div>
        <div class="card-body p-3">
            <div class="row">
            <div class="col-md-12 mb-2">
                <b-form-checkbox value="Y" unchecked-value="N" :name="'hasilLab'" v-model="isParent.rowEdit.arantl_hiv_is_menunggu_hasil">Hasil Pemeriksaan Belum Keluar
                </b-form-checkbox>
            </div>

            <div class="col-md-12">
                <table class="table table-bordered table-striped table-sm patient-table">
                <thead>
                    <tr>
                    <th>Hasil Pemeriksaan </th>
                    <th>Upload Hasil</th>
                    <th>Nilai Kritis</th>
                    <th>Jam Keluar Hasil Kritis</th>
                    <th>Aksi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v,k) in (isParent.rowEdit.arantl_hiv_hasil||[])" :key="k+'hasil'">
                    <td>
                        <b-form-textarea v-model="isParent.rowEdit.arantl_hiv_hasil[k]['value']" type="text" name="name" class="form-control" />
                    <VValidate :name="'Hasil Pemeriksaan #'+(k+1)" v-model="isParent.rowEdit.arantl_hiv_hasil[k]['value']"
                        :rules="{required:1, min:2}" />
                    </td>
                    <td>
                        <Uploader v-model="isParent.rowEdit.arantl_hiv_hasil[k]['file']" isDocument type="docimage" />
                        <VValidate :name="'File #'+(k+1)" v-model="isParent.rowEdit.arantl_hiv_hasil[k]['file']"
                            :rules="{required:1}" />
                    </td>
                    <td>
                        <b-form-textarea v-model="isParent.rowEdit.arantl_hiv_hasil[k]['nilai_kritis']" type="text" name="name" class="form-control" />
                    </td>
                    <td>                            
                        <div class="input-group">
                            <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                            <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="isParent.rowEdit.arantl_hiv_hasil[k]['jam_keluar']">
                            </vue-timepicker>
                        </div>
                        <VValidate v-if="isParent.rowEdit.arantl_hiv_hasil[k]['nilai_kritis']" :name="'Jam Keluar Hasil #'+(k+1)" v-model="isParent.rowEdit.arantl_hiv_hasil[k]['jam_keluar']"
                        :rules="{required:1}" />
                    <td> 
                    <a href="javascript:;" class="list-icons-item"
                        @click="isParent.rowEdit.arantl_hiv_hasil.splice(k,1)"
                        data-toggle="tooltip" data-placement="top" title="Delete"><i
                        class="icon-bin"></i></a>
                    </td>
                    </tr>
                    <tr v-if="!(isParent.rowEdit.arantl_hiv_hasil||[]).length">
                        <td colspan="99" class="text-center">Tidak Ada Data</td>
                    </tr>
                </tbody>
                </table>

                <div class="more_data">
                <a href="javascript:;" @click="addNew"><i class="icon-plus-circle2"></i>
                    Tambah</a>
                </div>
            </div>

            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="isParent.openModal = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i> 
                </button>
                </div>
            </div>
        </div>
      </div>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default{
  components:{ 
      VueTimepicker
  },
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    }
  },
  methods: {
    addNew(){
        this.isParent.rowEdit.arantl_hiv_hasil.push({
            value: null,
            file: null,
            dokter:null,
            nilai_kritis: null,
            jam_keluar: '',
            dilaporkan_oleh: null,
            dilaporkan_pada: null
        })
    },
    toValidate(val){
        return {...val}
    },
    doSubmit(){
      this.$refs['VFormHIV'].validate().then(success => {
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.isParent.rowEdit
              data.type = "submit-hasil-hiv"
              
              this.isParent.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.isParent.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.isParent.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    this.isParent.openModal = false
                    this.isParent.apiGet()
                  })
              }).catch(err => {
                  this.isParent.loadingOverlay = false
                  if (err) {
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response?.data?.title
                      err.message = err.response?.data?.message
                      err.messageError = err.message
                  }
                  this.isParent.doSetAlertForm(err)
              })
            }
          })
        }     
      })
    },  
  },
}

</script>
